import * as React from 'react';
import {useEffect} from "react";
import {listenPostMessageFromOtherWindow, postMessageToWindow, RoutedViewProps} from "../../utils";
import {LoadingIcon} from "../components/icons";

interface NewWindowStateOpenerPageProps extends RoutedViewProps {

}

export default function NewWindowStateOpenerPage(props: NewWindowStateOpenerPageProps) {
    const {uniqId} = props.match.params;
    useEffect(() => {
        listenPostMessageFromOtherWindow('openWithState', uniqId, event => {
            props.history.push(event.data.pathname, event.data.payload);
        }, true);
        postMessageToWindow('windowReady', uniqId);
        // eslint-disable-next-line
    }, [])
    return (
        <div><LoadingIcon /> Открываю...</div>
    );
}