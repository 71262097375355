import {getSessionId, isDev} from "../constants";
import {RestfulEndpoint, RestfulIOOptions} from "@type-r/endpoints";
import {createIOPromise, IOEvents, IOPromise} from "@type-r/models";

let isRedirecting = false;

class Endpoint extends RestfulEndpoint {

    list(options: RestfulIOOptions, collection: any) {
        const promiseOld: IOPromise<any> = super.list(options, collection);
        collection._lastFetchParams = options?.params;
        const promiseNew: IOPromise<any> = promiseOld.then(responseJson => {
            if (!responseJson || responseJson.isAborted) {
                return null;
            }
            collection.totalLength = responseJson.num_results;
            collection.page = responseJson.page;
            collection.totalPages = responseJson.total_pages;
            return responseJson.objects;
        });
        promiseNew.abort = promiseOld.abort;
        return promiseNew;
    }

    protected request(method: string, url: string, {options}: RestfulIOOptions, body?: any): IOPromise<any> {
        let _isAborted = false;
        let _timeout = null;
        const abortController: any = window["AbortController"] ? new AbortController() : null;
        if (abortController) {
            options = options || {};
            options.signal = abortController.signal;
        }
        return createIOPromise((resolve, reject, abort) => {
            _timeout = setTimeout(() => {
                _timeout = null;
                resolve(fetch(url, this.buildRequestOptions(method, options, body))
                    .then(response => {
                        if (_isAborted) {
                            console.log("Loaded aborted request");
                            return Promise.reject('Aborted');
                        }

                        if (response.ok) {
                            if (method.toUpperCase() === 'DELETE') {
                                return null;
                            }
                            return response.json();
                        }
                        return response.json().catch(err => {
                            throw new Error(response.statusText);
                        }).then(err => {
                            if (err.code === 401 && err.redirect_url) {
                                if (!isRedirecting) {
                                    if(window.top === window) {
                                        window.location.replace(
                                            err.redirect_url + '?next=' + encodeURIComponent(window.location.pathname)
                                            + (isDev ? '&host='+window.location.origin : '')
                                        );
                                        isRedirecting = true;
                                    }else{
                                        window['authLinkHref'] = err.redirect_url + '?next=' + encodeURIComponent(window.location.pathname);
                                        const needAskToAuthEvent = new CustomEvent('ask-auth', {
                                            detail:err.redirect_url + '?next=' + encodeURIComponent(window.location.pathname)
                                        });
                                        window.dispatchEvent(needAskToAuthEvent);
                                    }
                                }
                                throw new Error("Производится авторизация");
                            }
                            throw Object.assign(new Error(err.error || err.message), err);
                        })
                    }).catch(error=>{
                        if(error && error.code && error.code=== DOMException.ABORT_ERR){
                            return reject("Aborted");
                        }
                        throw error;
                    })
                );
            }, 10);

            abort((a_resolve, a_reject) => {
                if (_isAborted) {
                    return; // already aborted
                    // But it is safe because usually abort was before next request which will replace this promise
                }
                if (_timeout) {
                    if (method === 'GET') {
                        clearTimeout(_timeout);
                        _timeout = null;
                        console.warn("Don't send request", url);
                    } else {
                        console.warn("Could't abort " + method + ' request');
                        return;
                    }
                } else {
                    console.warn("Abort request", method, url);
                }
                _isAborted = true;
                if (abortController) {
                    abortController.abort();
                    return;
                }
                a_reject("Aborted");
            });
        });
    }

    protected objectUrl(record: any, id: string | number, options: any) {
        options = Endpoint.addUidParam(options);
        return super.objectUrl(record, String(id), options);
    }

    protected collectionUrl(collection: any, options: any) {
        options = Endpoint.addUidParam(options);
        return super.collectionUrl(collection, options);
    }

    protected static addUidParam(options: any) {
        if (!options) {
            options = {};
        }
        if (!options.params) {
            options.params = {}
        }
        options.params.uid = getSessionId();
        return options
    }

    subscribe(event: IOEvents, collection?: any): IOPromise<any> {
        return null;
    };
}

export function restfulIO(url: string, options: any = null) {
    return new Endpoint(url, Object.assign({mockData:null, simulateDelay:2000}, options));
}

export default Endpoint;