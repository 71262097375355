import { createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: blueGrey[600],
    },
  },
});

export const defaultTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: blueGrey[500],
    },
  },
});

