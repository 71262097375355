import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import logo from '../logo.svg';
import '../styles/App.css';
import {CircularProgress, CssBaseline} from "@material-ui/core";
import AppVersionView from "./components/AppVersionView";
import CurrentUserView from "./components/CurrentUserView";
import AskAuthDialogView from "./components/AskAuthDialogView";
import {ThemeProvider} from '@material-ui/styles';
import {darkTheme, defaultTheme} from "../styles/themes";
import useDarkThemeHook from "./hooks/useDarkThemeHook";
import NewWindowStateOpenerPage from "./pages/NewWindowStateOpenerPage";

function MyLoadable({loader, title, props}: { loader: () => Promise<any>, title?: string, props?: any }) {
    const [Component, setComponent] = React.useState(null);
    const useDarkTheme = useDarkThemeHook();
    useEffect(() => {
        loader().then(component => {
            if (component.loadState) {
                return component.loadState(props).then(() => {
                    setComponent(component);
                });
            }
            setComponent(component);
        });
        // eslint-disable-next-line
    }, []);
    return <ThemeProvider theme={useDarkTheme ? darkTheme : defaultTheme}>
        <CssBaseline/>
        {Component ? <Component.default {...(props || {})}/> : <div>Загружаю {title || '...'}<CircularProgress/></div>}
    </ThemeProvider>;
}

const TestPage = (props) => <MyLoadable loader={() => import('./pages/Test')} title={"Тест!"}/>;
const DirtyRegistryPageView = (props) => <MyLoadable
    loader={() => import("./pages/dirtyRegistry/DirtyRegistryPageView")}
    title={"Интерфейс реестра - поиск неучтённых знаков"}
/>;
const PortfolioPageView = (props) => <MyLoadable
    loader={() => import("./pages/portfolio/PortfolioPageView")}
    props={props}
    title={"Интерфейс реестра - портфель компании"}
/>;
const MarkRoutesView = (props) => <MyLoadable
    loader={() => import("./pages/portfolio/mark/MarkRoutesView")}
    props={props}
    title={"Интерфейс реестра - подробности ОИС"}
/>;

const ProvidersListPageView = (props) => <MyLoadable
    loader={() => import("./pages/providers/ProvidersListPageView")}
    props={props}
    title={"IPSolver - список провайдеров"}
/>;

const ProviderRoutesView = (props) => <MyLoadable
    loader={() => import("./pages/providers/ProviderRoutesView")}
    props={props}
    title={"IPSolver - интерфейс провайдера"}
/>;

const SBMarksListPage = (props) => <MyLoadable
    loader={() => import("./pages/SBMarksListPage")}
    props={props}
    title={"Интерфейс списка ОИС из SkyBase"}
/>;


function Page404() {
    return <header className="App-header">
        <img src={logo} className="App-logo" alt="logo"/>
        <p>
            Invalid URL
        </p>
    </header>;
}

function App() {
    const useDarkTheme = useDarkThemeHook();
    return (
        <Router>
            <div className={"App" + (useDarkTheme ? " darkSide" : '')}>
                <div className="build-version"><AppVersionView/><CurrentUserView/></div>
                <AskAuthDialogView>
                    <Switch>
                        <Route path="/test/:param" exact={true} component={TestPage}/>
                        <Route path="/skybase-marks" exact={true} component={SBMarksListPage}/>
                        <Route path="/skybase-marks/" exact={true} component={SBMarksListPage}/>
                        <Route path="/dirty-registry/:sbClientId" exact={true} component={DirtyRegistryPageView}/>
                        <Route path="/portfolio/:sbClientId" exact={true} component={PortfolioPageView}/>
                        <Route path="/portfolio/mark/:markId" exact={false} component={MarkRoutesView}/>
                        <Route path="/portfolio" exact={true} component={PortfolioPageView}/>

                        <Route path="/providers" exact={true} component={ProvidersListPageView}/>
                        <Route path="/providers/:providerId/storage-:storageId" exact={false} component={ProviderRoutesView}/>
                        <Route path="/providers/:providerId" exact={false} component={ProviderRoutesView}/>
                        <Route path="/goto/:uniqId" exact={false} component={NewWindowStateOpenerPage}/>
                        <Route component={Page404}/>
                    </Switch>
                </AskAuthDialogView>
            </div>
        </Router>
    );
}

export default App;
