import * as React from 'react';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessTwoToneIcon from '@material-ui/icons/BusinessTwoTone';
import WarningTwoToneIcon from '@material-ui/icons/WarningTwoTone';

import EditIcon from '@material-ui/icons/Edit';
import AddIcon from "@material-ui/icons/Add";
// import AddBoxTwoToneIcon from '@material-ui/icons/AddBoxTwoTone';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import ReplayTwoToneIcon from "@material-ui/icons/ReplayTwoTone";
import StorageTwoToneIcon from '@material-ui/icons/StorageTwoTone';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import UpdateIcon from '@material-ui/icons/Update';
// import EventNoteIcon from '@material-ui/icons/EventNote';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { RestoreFromTrash } from '@material-ui/icons';
// import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';

export const MarksIcon = BusinessCenterIcon;
export const LoadingIcon = React.forwardRef(()=><HourglassEmptyIcon className="loading"/>);
export const SearchIcon = ImageSearchIcon;
export const EventIcon = NotificationsIcon;
export const DangerIcon = WarningTwoToneIcon;
export const MarkOwnerIcon = BusinessTwoToneIcon
export const ReloadIcon = ReplayTwoToneIcon;
export const StorageIcon = StorageTwoToneIcon;
export const MatchOwnerNameIcon = AccountBalanceIcon;
export const ExtendingMarksIcon = UpdateIcon;
export const LiquidationToolIcon = RestoreFromTrash;

export {AddIcon, DeleteIcon, EditIcon, CloseIcon, SaveIcon};
