import * as React from 'react';
import {ThemeProvider} from "@material-ui/styles";
import {darkTheme, defaultTheme} from "../../styles/themes";
import {CssBaseline} from "@material-ui/core";
import useDarkThemeHook from "../hooks/useDarkThemeHook";

export default function AskAuthDialogView(props: { children?: any }) {
    const useDarkTheme = useDarkThemeHook();
    const [needAsk, setNeedAsk] = React.useState("");
    React.useEffect(() => {
        (window as any).addEventListener('ask-auth', (event: CustomEvent) => {
            setNeedAsk(event.detail);
        });
        // eslint-disable-next-line
    }, []);
    if (!needAsk) {
        return props.children;
    }
    return (
        <ThemeProvider theme={useDarkTheme ? darkTheme : defaultTheme} key={useDarkTheme ? 'dark' : 'default'}>
            <CssBaseline/>

            <div className="AskAuthDialogView">
                <a href={needAsk} target="_top">Вам необходимо авторизоваться. Нажмите на эту ссылку чтобы сделать
                    это.</a>
            </div>
        </ThemeProvider>
    );
}