let sessionId = null;
//Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
export function generateSessionId() {
    const randomChars = [];
    for (let i = 0; i < 32; i++) {
        randomChars.push((Math.floor(Math.random() * 36)).toString(36));
    }
    return randomChars.join('');

}

export function getSessionId() {
    if (!sessionId) {
        sessionId = generateSessionId();
    }
    return sessionId;
}

export const FIELDS_TO_SEARCH_FOR = {
    mark: 'Название',
    applicantAddress: "Адрес для переписки",
    companyName: "Правообладатель",
    patentLawyer: "Патентный поверенный",
    docNumber: "Номер документа",
}

export const baseURL = '/api/';
export const isProduction = process.env.NODE_ENV === 'production';
export const isDev = process.env.NODE_ENV !== 'production';
export const SUSPECTED_MARK_TYPE = 'suspected';
export const ARCHIVE_MARK_TYPE = 'archive';
export const APPLICATION_MARK_TYPE = 'rutmap';
export const DEFAULT_PROVIDER_ID = 1;

export const MARK_TYPE_NAMES = {
    suspected: 'предполагаемый',
    archive: 'в архиве',
    rutm: "свидетельство",
    rutmap: "заявка",

}
export const MARK_OWN_NEED_MANUAL = 'need_manual';
export const MARK_OWN_SOLD = 'sold';
export const MARK_OWN_STATE_NAMES = {
    register: 'владение на базе регистрации',
    bought: 'владение на базе отчуждения',
    sold: 'владение передано полностью',
    need_manual: 'владение требуется уточнить'
}

export const MARK_HAS_BEEN_EXPIRED_STATUSES = new Set([
    'прекратил действие', 'срок действия истек'
])

export const BASE_OGRN_URL = 'https://www.prima-inform.ru/cat/cc/';
// Варианты:
// https://zachestnyibiznes.ru/company/ul/1167746392440
// https://www.rusprofile.ru/search?query=1167746392440
// https://focus.kontur.ru/entity?query=1167746392440 (Контур, часть информации закрыта почему-то)
// https://www.prima-inform.ru/cat/cc/1167746392440
// https://star-pro.ru/proverka-kontragenta/organization/1167746392440
export const CHECK_MATCH_OWNER_TOOL_DANGER_SOURCE = "checkMatchOwner"
export const EXTENDING_TOOL_DANGER_SOURCE = "extendingMarks"