import React from "react";
import currentUserStore from "../../models/currentUserStore";

const useDarkThemeHook = () => {
    const [useDarkTheme, dispatch] = React.useReducer(
        (state, action)=>currentUserStore.preferences.useDarkTheme, currentUserStore.preferences.useDarkTheme);
    React.useEffect(()=>{
        currentUserStore.preferences.on('change', dispatch);
        // eslint-disable-next-line
    }, []);
    return useDarkTheme;
};
export default useDarkThemeHook;
