import {define, type, Model, AttributesMixin, Store} from '@type-r/models';
import {baseURL} from "./constants";
import * as Sentry from '@sentry/browser';
import ServerEventEndpoint, {LiveUpdateableModel} from "./endpoints/ServerEventEndpoint";
import {restfulIO} from "./endpoints/endpoint";
import PreferencesModel from "./PreferencesModel";


@define
class CurrentUserModel extends Model {
    static endpoint = restfulIO(baseURL + 'me');
    static attributes = {
        businessPhones: Array,
        displayName: String,
        givenName: String,
        jobTitle: String,
        mail: String,
        mobilePhone: String,
        officeLocation: String,
        preferredLanguage: String,
        surname: String,
        userPrincipalName: String,

        error: String
    };

    get id() {
        return null;
    }
}

interface CurrentUserModel extends AttributesMixin<typeof CurrentUserModel> {
}

@define
class FrontendModel
    extends LiveUpdateableModel {
    static endpoint = new ServerEventEndpoint(baseURL + 'ip_registry/');
    static attributes = {
        version: String,
        env: String
    };

    static get loadedVersion(): string {
        return process.env.REACT_APP_BUILD_NO || 'dev';
    }
}

interface FrontendModel extends AttributesMixin<typeof FrontendModel> {
}

@define
class CurrentUserStore extends Store {
    static attributes = {
        me: CurrentUserModel,
        frontend: type(FrontendModel).value({id: 'frontend'}),
        preferences: type(PreferencesModel).value({id: 'ip_registry'})
    };
}

interface CurrentUserStore extends AttributesMixin<typeof CurrentUserStore> {
}


const currentUserStore = new CurrentUserStore();
window['currentUserStore'] = currentUserStore;
class SimpleError extends Error {
    toString(){ return this.message }
}
currentUserStore.me.fetch().then(() => {
    if (!currentUserStore.me) {
        return
    }

    Sentry.setUser({
        email: currentUserStore.me.mail,
        user: currentUserStore.me.displayName,
    });
    currentUserStore.preferences.id = currentUserStore.me.mail || currentUserStore.me.userPrincipalName;
    currentUserStore.preferences.fetch().then(()=> {
        if(currentUserStore.preferences.hideSnowflakes !== null){
            currentUserStore.preferences.showSnowflakes = !currentUserStore.preferences.hideSnowflakes;
            throw new SimpleError("not found")
        }
    }).catch(async err => {
        if (String(err).toLowerCase() === "not found") {
            await (currentUserStore.hasPendingIO() || Promise.resolve());
            await currentUserStore.preferences.save();
        }
    }).then(() => {
        currentUserStore.preferences.on('change', () => {
            currentUserStore.preferences.save();
        })
    });
}).catch(err => {
    console.error(err);
});


currentUserStore.frontend && currentUserStore.frontend.liveUpdate && currentUserStore.frontend.liveUpdate(true);

export default currentUserStore;
export {CurrentUserModel, CurrentUserStore, FrontendModel};
