import {RouteComponentProps} from "react-router";
import * as Sentry from "@sentry/browser";
import {differenceInDays, differenceInMonths, differenceInYears, subMonths, subYears} from "date-fns";
import {baseURL, generateSessionId, isDev, isProduction} from "./models/constants";

function isApplicationId(itemId: string | number): boolean {
    const sid: string = String(itemId);
    if (sid.length < 10) {
        return false;
    }
    let year = parseInt(sid.substr(0, 4));
    return year > 1990 && year <= (new Date()).getFullYear();

}

export function externalFipsDocUrl(itemId: string, dbType?: string) {
    if (!dbType) {
        dbType = 'RUTM' + (isApplicationId(itemId) ? 'AP' : '');
    }
    return `http://www1.fips.ru/fips_servl/fips_servlet?DB=${dbType.toLowerCase()}&TypeFile=html&DocNumber=${itemId}`;
}

function formatDate(millis: any) { // millis can be number of milliseconds or string or date
    const d = new Date(millis);
    if (d.toLocaleDateString) {
        return d.toLocaleDateString();
    }
    return [d.getDate(), ('0' + (d.getMonth() + 1)).substr(-2), d.getFullYear()].join('.');
}

function getItemXmlLink(itemId: string, onlyPath: boolean = false): string {
    const isApplication = isApplicationId(itemId);
    const itemIdArray = itemId.split('');
    const pathArray = ['', 'xml'];

    pathArray.push(isApplication ? 'RUTMAP' : 'RUTM');
    if (isApplication) {
        pathArray.push('NEW' + itemId.substr(0, 4));
        itemIdArray.shift();
        itemIdArray.shift();
        itemIdArray.shift();
        itemIdArray.shift();
    }
    if (itemIdArray.length < 3) {
        pathArray.push('0');
    } else {
        for (let i = 0; i < itemIdArray.length - 3; i++) {
            pathArray.push(itemIdArray.map((x, j) => j > i ? '0' : x).join(''));
        }
    }
    pathArray.push(itemId);
    if (!onlyPath) {
        pathArray.push(itemId + '.xml');
    }
    return pathArray.join('/');
}

function addZeros(num: number, resultLength: number = 4): string {
    let result = `${'0'.repeat(resultLength)}${num}`;
    return result.substr(-resultLength);
}

window['getItemXmlLink'] = getItemXmlLink;
const extractNameFromAddressRegexp = /(.*?)\s*\d{6}/;

function extractNameFromAddress(address: string) {
    if (!address) {
        return '';
    }
    const matchResult = extractNameFromAddressRegexp.exec(address);
    if (matchResult) {
        address = matchResult[1];
    }
    return address.trim().replace(/[-,; .]*$/, '');
}

window['extractNameFromAddress'] = extractNameFromAddress;

function clone<T>(obj: T): any {
    let result = {};
    Object.keys(obj).forEach((k) => {
        result[k] = obj[k];
    });
    return result;
}

export interface AnyStringParams {
    [keys: string]: string
}

export interface RoutedViewProps extends RouteComponentProps<AnyStringParams> {
    match: { params: AnyStringParams }
    history: {
        push: (path: string, state?: any) => void
        replace: (path: string, state?: any) => void
        go: (path: string) => void
    }
    location: Record<string, any>
}

export interface LinkToProp {
    pathname?: string,
    search?: string,
    hash?: string,
    state?: any
}

export function plural(n: number, one: string, three: string, many: string) {
    n = Math.abs(Number(n));
    if (n > 4 && n < 20) {
        return many
    }
    n = n % 10;
    if (n === 1) {
        return one;
    }
    if (n > 1 && n < 5) {
        return three
    }
    return many;
}

export function setToArray(s: Set<any>) {
    return Array.from(s);
}

const timeStrRe = /\d\d:\d\d:\d\d/;

export function dateFromString(dateStr: string): Date {
    if (!dateStr) {
        return null;
    }
    if (timeStrRe.test(dateStr)) {
        dateStr = dateStr.split(' ')[0];
    }
    const [yr, mth, day] = dateStr.trim().split('-').map(Number);
    return new Date(yr, mth - 1, day);
}

export function fullYearsFrom(dateStr: string): number {
    if (!dateStr) {
        return null;
    }
    const dateDate = dateFromString(dateStr);
    const dateDiff = ((new Date() as any) - (dateDate as any)) as number;
    return Math.floor(dateDiff / 31536000000);
}

export function getDummy(Class: any, ...args: any[]) {
    if (!Class.__dummy) {
        Class.__dummy = new Class(...args);
    }
    return Class.__dummy;
}

let reportToSentry = null, addTraceMsg = null;

if (process.env.REACT_APP_BUILD_NO) {

    // Sentry.setBreadcrumbCallback(
    //     br => !br.message || (
    //         br.message.indexOf('textarea#report-message') < 0
    //         && br.message.indexOf('#send-report-btn') < 0
    //         && br.message.indexOf('#error-btn') < 0)
    // );
    reportToSentry = (msg: string, options?: any) => {
        if (!options) {
            options = {}
        }
        if (!options.fingerprint) {
            options.fingerprint = 'frontend report'
        }
        Sentry.captureMessage(msg, options);
    };
    addTraceMsg = (msg: string) => {
        console.info('toSentry (trace):', msg)
        // Sentry.captureBreadcrumb({
        //     message: msg,
        //     level: "debug"
        // })
    }
} else {
    reportToSentry = (msg: string, options?: object) => {
        console.info('toSentry:', msg, options);
    };
    addTraceMsg = (msg: string) => console.info('toSentry (trace):', msg);
}

let simpleFlatArray: (a: any[]) => any[];
if (Array.prototype['flat']) {
    simpleFlatArray = function (a: any) {
        return a.flat()
    }
} else {
    simpleFlatArray = function (a: Array<any>) {
        return a.reduce((acc, val) => acc.concat(val), [])
    }
}

export function compareForSort(a: number, b: number, whenEqual?: (a: number, b: number) => -1 | 0 | 1): 1 | 0 | -1 {
    if (a === b) {
        return whenEqual ? whenEqual(a, b) : 0;
    }
    return a > 0 ? 1 : -1;
}

const STARTER_ALLOWED_PARAMS = ['flag', 'force'];

export function parseStarterParamsMethod(): AnyStringParams | null {
    const stepId = this.props.match.params.stepId || '';
    const qsParams = new URLSearchParams(this.props.location.search ? this.props.location.search.substr(1) : '');

    let params: any = null;
    if (stepId) {
        params = {stepId};
    }

    STARTER_ALLOWED_PARAMS.forEach(paramName => {
        const paramValue = qsParams.get(paramName);
        if (paramValue === null) {
            return
        }
        if (!params) {
            params = {}
        }
        params[paramName] = paramValue;
    });
    return params;
}

export function formatDistanceToNowExact(date: Date,agoWord="назад") {
    const result = [];
    let now = new Date();
    let future = date;
    const ago = date < now;
    if(ago){
        now = date;
        future = new Date();
    }

    const years = differenceInYears(future, now);
    if (years !== 0) {
        result.push(`${Math.abs(years)} ${plural(years, 'год', 'года', 'лет')}`);
        future = subYears(future, years);
    }

    const months = differenceInMonths(future, now);
    if (months !== 0) {
        result.push(`${Math.abs(months)} ${plural(months, 'месяц', 'месяца', 'месяцев')}`);
        future = subMonths(future, months);
    }

    const days = Math.abs(differenceInDays(future, now)) + 1;
    if (days !== 0) {
        result.push(`${Math.abs(days)} ${plural(days, 'день', 'дня', 'дней')}`);
    }

    if(ago && agoWord){
        result.push("назад")
    }
    return result.join(' '); //1 years 4 months 13 days (ago)
}


type rpcAsyncMethod = (params: Record<string, any>) => Promise<any>;

export const registryRPC: Record<string, rpcAsyncMethod> = new Proxy({}, {
    get(self: object, name: string) {
        self[name] = (params: any) => callRegistryRPC(name, params);
        return self[name];
    }
});
window['registryRPC'] = registryRPC;

const callRegistryRPC = getCallAnyRPCFunction('call_rpc_action')

function getCallAnyRPCFunction(endpointPath: string) {
    return async (procedureName: string, params: Record<string, any>) => {
        const response = await fetch(baseURL + endpointPath + '/' + procedureName, {
            method: 'POST', headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, body: JSON.stringify(params)
        });
        let json = null;
        try {
            json = await response.json();
        } catch (e) {
            json = null;
        }
        if (response.status === 200) {
            return json
        }
        throw json || {message: response.statusText, code: response.status}
    }
}

export function createFilterOptionsBinaryChoose(whenNotNull: string, whenNull: string, onlyNull?: boolean) {
    return {
        names: [whenNotNull, whenNull],
        makeServerFilter(filters: string[], fieldName: string) {
            if (filters.length !== 1) {
                return null
            }
            if (filters[0] === whenNotNull) {
                return onlyNull ? {'op': 'is_not_null', name: fieldName} : {
                    "and": [
                        {'op': 'is_not_null', name: fieldName},
                        {'op': '!=', name: fieldName, val: ""},
                    ]
                }
            }
            if (filters[0] === whenNull) {
                return onlyNull ? {'op': 'is_null', name: fieldName} : {
                    "or": [
                        {'op': 'is_null', name: fieldName},
                        {'op': '==', name: fieldName, val: ""},
                    ]
                }
            }
            return null
        },
        logic(value: string, filters: any[]) {
            if (filters.length >= 2) {
                return false;
            }
            if (filters.length <= 0) {
                return true;
            }
            if (filters[0] === whenNotNull) {
                return !value
            }
            if (filters[0] === whenNull) {
                return Boolean(value)
            }

            return false;
        },
    }
}

export const filterOptionsFilledOrNotFilter = createFilterOptionsBinaryChoose("Заполнено", "Не заполнено");

function calcDevelopNewWindowName(pathname: string) {
    return pathname
        .replace(/\//g, ' ')
        .trim()
        .split(' ')
        .filter(part => Number.isNaN(Number(part)))
        .join('_');
}

export function openInNewWindowWithState(pathname: string, state: any) {
    if (isDev) {
        console.log(calcDevelopNewWindowName(pathname));
    }
    if (!state) {
        console.warn('Use openInNewWindowWithState to open with state')
        window.open(pathname,
            isProduction ? '_blank' : calcDevelopNewWindowName(pathname)
        );
        return
    }
    const uniqId = generateSessionId();
    if (state.toJSON) {
        state = state.toJSON();
    }
    const listener = event => {
        if (event.origin !== window.location.origin
            || event.data.system !== 'ipsolver'
            || event.data.destination !== uniqId
            || event.data.action !== 'windowReady'
        ) {
            return;
        }
        window.removeEventListener('message', listener);

        postMessageToWindow(
            'openWithState', uniqId, state,
            newWindow, pathname
        );

    };
    window.addEventListener('message', listener);
    const newWindow = window.open('/goto/' + uniqId,
        isProduction ? '_blank' : calcDevelopNewWindowName(pathname)
    );
}

export function postMessageToWindow(action: string, uniqId: string, payload?: any, newWindow?: Window, pathname?: string) {
    (newWindow || window.opener).postMessage(
        {
            payload: payload || {},
            system: 'ipsolver',
            destination: uniqId,
            action,
            pathname
        },
        window.location.origin
    );
}

export function listenPostMessageFromOtherWindow(
    action: string, uniqId: string,
    listener: (event: MessageEvent) => any,
    once?: boolean) {
    const _listener = (event: MessageEvent) => {
        if (event.origin !== window.location.origin
            || event.data.system !== 'ipsolver'
            || (uniqId && event.data.destination !== uniqId)
            || event.data.action !== action
        ) {
            return;
        }
        if (once) {
            window.removeEventListener('message', _listener);
        }
        return listener(event);
    }
    window.addEventListener('message', _listener);
}

export {
    addZeros, extractNameFromAddress,
    getItemXmlLink, clone, isApplicationId,
    formatDate,
    reportToSentry, addTraceMsg, simpleFlatArray
};
export const dateOrStringToJSON = (value) => (
    !value ? value
        : ((typeof (value) === 'string') ? value : (value as Date).toISOString().split('.')[0])
);

export function differenceInDaysForMessage(d1, d2) {
    const diffDays = differenceInDays(d1, d2);
    return `${diffDays} ${plural(diffDays, 'день', "дня", "дней")}`
}

export function applyMixins(derivedCtor: any, constructors: any[]) {
    constructors.forEach((baseCtor) => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
            Object.defineProperty(
                derivedCtor.prototype,
                name,
                Object.getOwnPropertyDescriptor(baseCtor.prototype, name)
            );
        });
        if (baseCtor.attributes) {
            derivedCtor.attributes = Object.assign(derivedCtor.attributes || {},
                baseCtor.attributes)
            if (derivedCtor.define) {
                derivedCtor.define();
            }
        }
    });
}

export function removeEmptyKeys(object: Record<string, any>) {
    Object.keys(object).forEach(keyName => {
        if (!object[keyName]) {
            delete object[keyName];
        }
    });
    return object
}

export function getValueByDotPath(obj: Record<string, any>, path: string): any {
    return path.split('.').reduce((obj, pathPart) => obj && obj[pathPart], obj)
}