import Endpoint from "./endpoint";
import {Collection, IOEvents, LiveUpdatesOption, Model, Record} from "@type-r/models";
import ServerEvents from "./ServerEvents";

class ServerEventEndpoint extends Endpoint {

    subscribe(events: IOEvents, object: (Collection<any> | Record) & { serverEvents?: ServerEvents; }) {
        if (object.serverEvents) {
            this.unsubscribe(events, object)
        }
        let url =
            this.objectUrl(object,
                // eslint-disable-next-line
                (object instanceof LiveUpdateableModel) && object.id || ''
                , {}).split('/api/')[1].replace('//', '/');
        // if ((object instanceof LiveUpdateableModel) && object.id) {
        //     if (url.substr(url.length - 1, 1) !== '/') {
        //         url += '/';
        //     }
        //     url += String(object.id);
        // }
        object.serverEvents = new ServerEvents(url, object);
        object.on('server_changed', (msg) => {
            events.updated(msg);
        });
        if (object instanceof Collection) {
            object.on('server_deleted', (msg) => {
                events.removed(msg)
            });
            object.on('server_created', (msg) => {
                events.updated(msg)
            });
        }
        return object.serverEvents.connect();
    }

    unsubscribe(events: any, object?: any) {
        if (!object.serverEvents) {
            return
        }
        object.serverEvents.close();
        object.serverEvents = null;
    }
}

export class LiveUpdateableModel extends Model {
    _liveUpdates: object;
    serverEvents: ServerEvents;

    liveUpdate(enabled?: LiveUpdatesOption) {
        if (enabled) {

            this.liveUpdate(false);
            const filter = typeof enabled === 'function' ? enabled : () => true;
            const model = this;
            this._liveUpdates = {
                updated: json => {
                    if (filter(json)) {
                        model.set(json, {parse: true, merge: true});
                    }
                },

                removed: () => model.destroy()
            };
            return this.getEndpoint().subscribe(this._liveUpdates, this).then(() => this);
        } else {
            if (this.serverEvents) {
                this.serverEvents.close();
                this.serverEvents = null;
            }
            if (this._liveUpdates) {
                this.getEndpoint().unsubscribe(this._liveUpdates, this);
                this._liveUpdates = null;
            }
        }
    }
};
export default ServerEventEndpoint;