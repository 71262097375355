import {getSessionId} from "../constants";


function generateWSUrl(): string {

    let wsBaseUrl: string = '/ws/';
    if (process.env.NODE_ENV !== 'production') {
        wsBaseUrl = 'ws://localhost:8765/ws/'; // on production here will be just valid path
    }
    const re = new RegExp('wss?://', 'i');
    if (wsBaseUrl.match(re)) {
        return wsBaseUrl;
    }
    // Interpret wsBaseUrl as only path
    return window.location.origin.toString().replace('http', 'ws') + wsBaseUrl;
}

const WS_BASE_URL = generateWSUrl(); // there might be something like window.location.origin.replace('http','ws')
const RECONNECT_TIMEOUT = 2000;

class ServerEvents {
    socket: WebSocket;
    url: string;
    eventsChannel: any;
    _needReconnect: boolean;

    constructor(channel: string, eventsChannel: any) {
        if (!WebSocket) {
            throw Error("Error! use modern browser please!");
        }
        this.eventsChannel = eventsChannel;
        this.url = WS_BASE_URL + channel;
        this.socket = null;
        this._needReconnect = true;
    }

    connect() {
        this.socket = new WebSocket(this.url);
        this.socket.onclose = () => {
            setTimeout(() => {
                if (this._needReconnect) {
                    this.connect()
                }
            }, RECONNECT_TIMEOUT);
        };
        this.socket.onmessage = this.parseMessage;
        let isResolved = false;
        return new Promise((resolve, reject) => {
            this.socket.onopen = (event) => {
                resolve();
                isResolved = true;
            };
            this.socket.onerror = (event) => {
                if(isResolved){
                    console.error(event);
                    return
                }
                reject(event);
            }
        });

    }

    private parseMessage = (messageEvent: MessageEvent) => {
        const message = JSON.parse(messageEvent.data);
        if (message.source && message.source === getSessionId()) {
            // Ignore my messages - they are already applied.
            return;
        }
        this.eventsChannel.trigger(message.event, message.payload)
    };

    close() {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            return;
        }
        this.socket.onclose = null;
        this._needReconnect = false;
        this.socket.close();
    }
}

export default ServerEvents;