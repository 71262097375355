import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './views/App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { Integrations } from "@sentry/tracing";
import {getSessionId} from "./models/constants";

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);


if (process.env.REACT_APP_BUILD_NO && process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.FRONTEND_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0
    });
    Sentry.setExtra('sessionId', getSessionId());
    window['Sentry'] = Sentry;
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
