import * as React from 'react';
import {define, Model, refTo, AttributesMixin} from '@type-r/models';
import currentUserStore, {FrontendModel} from "../../models/currentUserStore";
import {useModel} from "@type-r/react";

interface AppVersionViewProps {

}

@define
class AppVersionViewState extends Model {
    static attributes = {
        frontend: refTo(FrontendModel).value(currentUserStore.frontend)
    }
}
interface AppVersionViewState extends AttributesMixin<typeof AppVersionViewState> {
}


export function AppVersionView(props: AppVersionViewProps) {
    const state: AppVersionViewState = useModel(AppVersionViewState);
    const [hasNewFrontend, setHasNewFrontend] = React.useState<boolean>(false);
    React.useEffect(() => {
        currentUserStore.frontend.onChanges(()=>{
            const hasNewFrontend = Boolean(currentUserStore.frontend?.version)
                && currentUserStore.frontend?.version !== 'unknown'
                && currentUserStore.frontend?.version !== FrontendModel.loadedVersion;
            setHasNewFrontend(hasNewFrontend)
        });
        // eslint-disable-next-line
    }, []);

    return (
        <React.Fragment>
            {hasNewFrontend && <button
                                  onClick={reloadPage}
                                  title={"Интерфейс изменился, нужно перезагрузить страницу чтобы применить изменения. " +
                                  "Новая версия №" + state.frontend?.version
                                  }
            ><span role="img" aria-label="Need reload">❗️🔄</span></button>}
            <span
                title={"version=" + (state.frontend ? (state.frontend.version || '-') : 'noFE')}>{FrontendModel.loadedVersion}</span>
        </React.Fragment>
    );
}

const reloadPage = () => {
    try {
        window.location.reload(true);
    } catch (err) {
        (window.location as any) = String(window.location);
    }
};

export default AppVersionView;