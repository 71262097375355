import {define, Record, AttributesMixin, type} from '@type-r/models';
import {localStorageIO} from "@type-r/endpoints";

@define
class PreferencesModel extends Record {
    static endpoint = localStorageIO('preferences');
    static attributes = {
        isDebug: Boolean,
        useDarkTheme: Boolean,
        hideSnowflakes: type(Boolean).value(null),
        showSnowflakes: Boolean
    };

    static PreferencesCheckboxesTitles = {
        isDebug: 'Показывать отладочные метки',
        useDarkTheme: 'Перейти на темную сторону',
        showSnowflakes: 'Завали меня деньгами 💰💵💸)'
    }
}

interface PreferencesModel extends AttributesMixin<typeof PreferencesModel> {
}

export default PreferencesModel;