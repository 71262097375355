import * as React from 'react';
import {generateSessionId, getSessionId} from "../../models/constants";
import currentUserStore, {CurrentUserModel} from "../../models/currentUserStore";
import {useModel} from "@type-r/react";
import {AttributesMixin, define, Model, refTo} from "@type-r/models";
import PreferencesModel from "../../models/PreferencesModel";
import {Menu, MenuItem} from "@material-ui/core";
import {CheckBoxOutlineBlank, CheckBoxOutlined} from "@material-ui/icons";
import {useEffect} from "react";


const SCREENSHOT_BASE_URL = 'https://storageproxies.blob.core.windows.net/usolver-screenshots/';
const SCREENSHOT_KEY_QS = '?sv=2018-03-28&ss=b&srt=co&sp=rwdlac&se=2019-12-31T16:44:43Z&st=2019-08-29T08:44:43Z&spr=https&sig=ECsfQDgA15DjMoykSLOdG7tk6nFcjjyVgt95PaGF%2FUw%3D';

async function makeScreenshot() {
    const html2canvas: any = await import('html2canvas');
    const canvas = await html2canvas(document.body);

    const filename = new Date().toISOString()
            .replace(/[:-]/g, '')
            .replace('.', '_')
        + '-' + getSessionId();

    console.log(canvas, filename);
    canvas.toBlob((ssBlob) => {
        (window as any).ssBlob = ssBlob;
        fetch(SCREENSHOT_BASE_URL + filename + '.png' + SCREENSHOT_KEY_QS, {
            method: 'PUT',
            body: ssBlob,
            mode: "cors",
            headers: {
                "x-ms-client-request-id": generateSessionId(),
                "x-ms-blob-content-type": ssBlob.type,
                // "x-ms-blob-content-length": String(Math.ceil(ssBlob.size / 512) * 512),
                "x-ms-blob-type": "BlockBlob",
                "x-ms-meta-user": currentUserStore.me.userPrincipalName,
                "x-ms-meta-location": String(window.location)
            }
        }).then(r => {
            console.warn("Screenshot saved to:", SCREENSHOT_BASE_URL + filename + '.png');
        })
    });
    (window as any).lastScreenshotCanvas = canvas;
    return SCREENSHOT_BASE_URL + filename + '.png';
}

// function ReportErrorDialog({onClose, ssUrl}: { onClose: () => void, ssUrl?: string }) {
//     const [reportText, setReportText] = React.useState('');
//
//     function sendReport() {
//         reportToSentry(reportText + (ssUrl ? '\n' + ssUrl : ''), {level: "error"});
//         onClose();
//     }
//
//     return <Dialog
//         isOpen={true}
//         onClose={onClose}
//         title={"Отправить отчёт об ошибке"}
//     >
//         <div className="pt-dialog-body">
//                     <textarea id="report-message"
//                               style={{width: '100%', height: '100%'}}
//                               ref={(item) => item && item.focus()}
//                               onChange={(event) => setReportText(event.target.value)}
//                               value={reportText}
//                     />
//         </div>
//         <div className="pt-dialog-footer">
//             <div className="pt-dialog-footer-actions">
//                 <Button text="Отправить" onClick={sendReport} id="send-report-btn"/>
//                 <Button text="Отмена" onClick={onClose}/>
//             </div>
//         </div>
//     </Dialog>
// }
//
// @define
// class CurrentUserViewState extends Record {
//     @attr(currentUserStore.me) me: CurrentUserModel;
//     @attr(false) isReportDialogShown: boolean;
//     @attr(false) isReportButtonShown: boolean;
//     @attr(false) doing: boolean;
//     @attr('') ssUrl: string;
//     @attr(globalStore.preferences) preferences: PreferencesModel;
//
//     static changePreferences(name: string) {
//         globalStore.preferences[name] = !globalStore.preferences[name];
//         globalStore.preferences.save()
//     }
// }
//
// @define
// class CurrentUserView extends Component<CurrentUserViewProps> {
//     static state = CurrentUserViewState;
//     state: CurrentUserViewState;
//     _btnTimer: any;
//
//     render() {
//         return (
//             <div onMouseOver={this.showButton} onMouseOut={this.hideButton}>
//                 {this.state.isReportDialogShown && <ReportErrorDialog
//                     onClose={this.closeDialog}
//                     ssUrl={this.state.ssUrl}
//                 />}
//                 <Popover
//                     hoverOpenDelay={500}
//                     transitionDuration={333}
//                     interactionKind={PopoverInteractionKind.HOVER}
//                     position={Position.BOTTOM_RIGHT}
//                 >
//                     <div>{this.state.me && this.state.me.displayName || '🎛🎚🔧⚙️'}</div>
//                     <Menu>
//                         <MenuItem onClick={this.showDialog} text="Зафиксировать ошибку!"/>
//                         <MenuDivider/>
//                         {Object.keys(PreferencesModel.PreferencesCheckboxesTitles).map(name => <MenuItem
//                             key={name}
//                             text={PreferencesModel.PreferencesCheckboxesTitles[name]}
//                             onClick={() => CurrentUserViewState.changePreferences(name)}
//                             iconName={
//                                 this.state.preferences[name]
//                                     ? 'tick' : 'square'
//                             }/>)}
//
//                     </Menu>
//                 </Popover>
//             </div>
//         );
//     }
//
//     showDialog = () => {
//         this.state.doing = true;
//         makeScreenshot().then((ssUrl) => {
//             this.state.set({
//                 isReportDialogShown: true,
//                 isReportButtonShown: false,
//                 ssUrl,
//                 doing: false
//             });
//         }).catch(err => {
//             console.error(err);
//             this.state.doing = false;
//         })
//     };
//     closeDialog = () => {
//         this.state.isReportDialogShown = false;
//         this.state.isReportButtonShown = false;
//     };
//     showButton = () => {
//         if (this._btnTimer) {
//             clearTimeout(this._btnTimer);
//         }
//         this._btnTimer = setTimeout(() => {
//             this._btnTimer = null;
//             this.state.isReportButtonShown = true
//         }, 2000);
//     };
//     hideButton = () => {
//         if (this._btnTimer) {
//             clearTimeout(this._btnTimer);
//         }
//         if (this.state.isReportButtonShown) {
//             this._btnTimer = setTimeout(() => {
//                 this._btnTimer = null;
//                 this.state.isReportButtonShown = false;
//             }, 500);
//         }
//     };
// }
//
@define
class CurrentUserViewModel extends Model {
    static attributes = {
        me: refTo(CurrentUserModel).value(currentUserStore.me),
    };

    changePreferences(fieldName) {
        currentUserStore.preferences[fieldName] = !currentUserStore.preferences[fieldName];
        if(fieldName === 'showSnowflakes'){
            if(currentUserStore.preferences[fieldName]){
                initSnowFlakes(window);
            }else{
                removeSnowFlakes(window)
            }
        }
    }
}

interface CurrentUserViewModel extends AttributesMixin<typeof CurrentUserViewModel> {
}

function CurrentUserView() {
    const state = useModel(CurrentUserViewModel);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    let timer = null;
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if(timer){
            clearTimeout(timer);
        }
        const newTarget = event.currentTarget;
        timer = setTimeout(()=>{timer=null; setAnchorEl(newTarget);}, 500);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(()=>{
        setTimeout(()=>{
            if(!state.me.mail || !currentUserStore.preferences.showSnowflakes){
                return
            }
            initSnowFlakes(window);
        }, 2000);
    }, [state.me.mail]);

    const cancelOpenMenu = ()=>{
        if(timer){
            clearTimeout(timer);
            timer = null;
        }
    };
    return <React.Fragment>
        <div aria-haspopup={true} onMouseEnter={handleClick} onMouseLeave={cancelOpenMenu}>
            {state.me ? state.me.displayName : '🎛🎚🔧⚙️'}</div>
        <Menu
            id="preferences-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem style={{display:'none'}} onClick={makeScreenshot}> Зафиксировать ошибку!</MenuItem>
            {Object.keys(PreferencesModel.PreferencesCheckboxesTitles).map(name => <MenuItem
                key={name}
                dense={true}
                onClick={() => {
                    state.changePreferences(name);
                    handleClose();
                }}
            >
                {currentUserStore.preferences[name]
                    ? <CheckBoxOutlined fontSize={"small"}/> : <CheckBoxOutlineBlank fontSize={"small"}/>
                }
                {PreferencesModel.PreferencesCheckboxesTitles[name]}</MenuItem>)}
        </Menu>
    </React.Fragment>;

}
function removeSnowFlakes(w: any) {
    if(!w.snowflakes){
        return
    }
    w.snowflakes.stop();
    w.snowflakes.destroy();
    w.snowflakes = null;
}

function initSnowFlakes(w: any) {
    if(w.snowflakes){
        return
    }
    try {
        const Snowflakes = require('magic-snowflakes');
        const sf = Snowflakes({
            count: 50,
            speed: 1.2,
            color: 'white',
            minSize: 18,
            maxSize: 18
        });
        w.snowflakes = sf;
        console.log("Showflakes - ", sf);
    } catch (e) {
        console.error(e);
        console.log("no snowflakes :-(");
    }
}
export default CurrentUserView;